<template>
  <div>
    <iframe
      :src="url"
      frameborder="0"
      style="width:100%;height:100vh"
    />
  </div>
</template>

<script>

export default {
  data() {
    return {
      url: `${process.env.VUE_APP_STREAM_URL}/${this.$route.params.id}/donation-goal`,
    }
  },
}
</script>

<style scoped>

</style>
